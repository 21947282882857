var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "settings container",
    },
    [
      _c("iq-title", { attrs: { "class-name": "desktopHeader" } }, [
        _vm._v(
          " Настройки для терминала: " + _vm._s(_vm.currentTerminal) + " "
        ),
      ]),
      _c("div", { staticClass: "settings__card container" }, [
        _c("div", { staticClass: "settings__half-container" }, [
          _c(
            "div",
            { staticClass: "settings__main-section" },
            [
              _c(
                "div",
                { staticStyle: { flex: "1" } },
                [
                  _c("editor", {
                    attrs: { content: _vm.contentForEditor },
                    on: {
                      "update:content": function ($event) {
                        _vm.contentForEditor = $event
                      },
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "settings__btn-box" },
                    [
                      _c(
                        "iq-button",
                        {
                          attrs: { color: "second" },
                          on: {
                            onClick: function ($event) {
                              _vm.contentForEditor = ""
                            },
                          },
                        },
                        [_vm._v(" Очистить ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.contentForEditor
                ? _c("el-card", {
                    staticClass: "settings__preview",
                    domProps: { innerHTML: _vm._s(_vm.contentForEditor) },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "settings__sizes" },
          [
            _c("require-inn", {
              attrs: { "require-inn": _vm.requireInn },
              on: { setRequireInn: _vm.updateRequireInn },
            }),
            _vm.isSuperAdmin || _vm.isAdmin
              ? _c("buffer-size", {
                  staticClass: "settings__sizes-item",
                  attrs: { "current-terminal-size": _vm.bufferSize },
                  on: { setBufferZoneSize: _vm.updateBufferSize },
                })
              : _vm._e(),
            _vm.isSuperAdmin && _vm.canCreateManualsTs
              ? _c("limit-size", {
                  attrs: {
                    "current-limit-size": _vm.limitSize,
                    "current-limit-state": _vm.limitState,
                  },
                  on: {
                    setLimitState: _vm.updateLimitState,
                    setLimitSize: _vm.updateLimitSize,
                  },
                })
              : _vm._e(),
            _vm.isSuperAdmin
              ? _c("arrival-prefix-size", {
                  staticClass: "m-t-20",
                  attrs: { "current-prefix-size": _vm.arrivalPrefixSize },
                  on: { setPrefixSize: _vm.updatePrefixSize },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "settings__btn-box" },
          [
            _c(
              "iq-button",
              { on: { onClick: _vm.setSettings } },
              [
                _c("Icon", { attrs: { name: "SaveIcon" } }),
                _vm._v(" Сохранить "),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }